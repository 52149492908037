import { getRequest, postRequest, putRequest } from './index'
import { scfURL } from '@/systemConfiguration/index'
// 产品分页列表
export const productPage = (data, successCallback, failureCallback) => {
  postRequest(scfURL + '/product/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 产品详情
export const productDetails = (id, successCallback, failureCallback) => {
  getRequest(scfURL + `/product/detail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 新增产品
export const productAdd = (data, successCallback, failureCallback) => {
  postRequest(scfURL + `/product/add`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 编辑产品
export const productEdit = (data, successCallback, failureCallback) => {
  putRequest(scfURL + `/product/edit`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 禁用启用产品
export const productDisabled = (data, successCallback, failureCallback) => {
  putRequest(scfURL + `/product/product`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 验证担保方是否在会员中心
export const productValidate = (guarId, successCallback, failureCallback) => {
  getRequest(scfURL + `/product/validate/guar?guarId=${guarId}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 验证资金方方是否存在产品
export const productValidateFunder = (funderId, successCallback, failureCallback) => {
  getRequest(scfURL + `/product/validate/funder?funderId=${funderId}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取资金方
export const productFunderList = (value, successCallback, failureCallback) => {
  getRequest(scfURL + `/product/funder/list?funderName=${value || ''}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取担保方
export const productGuaranteeList = (value, successCallback, failureCallback) => {
  getRequest(scfURL + `/product/guarantee/list?guaranteeCmpName=${value || ''}`, { guaranteeCmpName: value }, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 合同模板客户端所有的
export const compactServiceGetCompactByType = (compactType, successCallback, failureCallback) => {
  getRequest(`/compactService/getCompactByType?compactTypes=${compactType || []}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 获取融资管理列表
export const financeManagementList = (data, successCallback, failureCallback) => {
  postRequest(scfURL + '/finance/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// finance/finance/detail   详情
export const detailsFinanceManagement = (id, successCallback, failureCallback) => {
  postRequest(scfURL + `/finance/finance/detail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 授信列表
export const creditManagementList = (data, successCallback, failureCallback) => {
  postRequest(scfURL + '/credit/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// /credit/detail/{id}
// 授信详情
export const detailsCreditManagement = (id, successCallback, failureCallback) => {
  getRequest(scfURL + `/credit/detail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 产品基础信息审核
export const productAudit = (data, successCallback, failureCallback) => {
  putRequest(scfURL + '/product/audit', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 授信基础信息审核
export const creditAudit = (data, successCallback, failureCallback) => {
  putRequest(scfURL + '/credit/audit', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
